import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { CursorProvider } from './components/providers/Cursor';
import { BGColorProvider } from './components/providers/ColorContainer';
import { ConfigProvider } from 'antd';
import esES from 'antd/lib/locale/es_ES'
import Theme from './Data/Theme.json';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <React.StrictMode>
    <ConfigProvider locale={esES} theme={Theme}>
      <CursorProvider>
        <BGColorProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </BGColorProvider>
      </CursorProvider>
    </ConfigProvider>
  </React.StrictMode>
);