import React, { useContext, useState } from 'react'
import Cta from '../ui/cta'
import ColorContext from '../providers/ColorContainer'
import { SlArrowDown } from 'react-icons/sl'
import CursorContext from '../providers/Cursor'
import { AiOutlineClose } from 'react-icons/ai'
import { Button } from 'antd'
import { motion } from 'framer-motion'
import { NavLink } from 'react-router-dom'
import { FaFacebook, FaInstagram, FaLinkedin, FaTiktok, FaYoutube } from 'react-icons/fa'


function Navbar() {
    const { bgColor } = useContext(ColorContext)
    const [openMenu, setOpenMenu] = useState(false)
    const { selectEnter, selectExit, navBarEnter, navBarExit } = useContext(CursorContext)


    const socialNetworks = [
        {
            Icon: FaLinkedin,
            name: 'LinkedIn',
            url: 'https://www.linkedin.com/company/awnadigital'
        },
        {
            Icon: FaFacebook,
            name: 'Facebook',
            url: 'https://www.facebook.com/profile.php?id=61567877822933'
        },
        {
            Icon: FaInstagram,
            name: 'Instagram',
            url: 'https://www.instagram.com/awna_digital/'
        },
        {
            Icon: FaTiktok,
            name: 'TikTok',
            url: 'https://www.tiktok.com/@awnadigital'
        },
        {
            Icon: FaYoutube,
            name: 'YouTube',
            url: 'https://www.youtube.com/@Awnadigital'

        }
    ];

    return (
        <div className='w-full fixed pt-[33px] z-index-99  '>
            <div className='container flex justify-between px-5 md:px-10 2xl:px-0'>
                <NavLink onMouseEnter={selectEnter} onMouseLeave={selectExit} to={'/'}>
                    <img src={
                        bgColor == 'white' ?
                            'https://awna.s3.sa-east-1.amazonaws.com/Vector.png' :
                            'https://awna.s3.sa-east-1.amazonaws.com/LogoWhite.png'
                    } className='w-[54px] h-[40px]' />
                </NavLink>
                <div className='flex gap-10 items-center'>
                    <div className='hidden md:block'>
                        <Cta text={'Contacto'} changeColor link={'/contact'} />
                    </div>
                    {
                        !openMenu ?
                            <div onClick={() => setOpenMenu(true)} onMouseEnter={selectEnter} onMouseLeave={selectExit} className='flex gap-2 items-center '>
                                <SlArrowDown color={
                                    bgColor == 'white' ?
                                        'black' :
                                        'white'
                                } />
                                <p style={{
                                    color: bgColor == 'white' ? 'black' : 'white'
                                }}>
                                    Menú
                                </p>
                            </div>
                            :
                            <div onClick={() => setOpenMenu(false)} onMouseEnter={selectEnter} onMouseLeave={selectExit} className='z-index-999999 text-white relative'>
                                <AiOutlineClose size={30} />
                            </div>
                    }
                </div>
            </div>
            <div onMouseEnter={navBarEnter} onMouseLeave={navBarExit} className={openMenu ? 'fixed left-0 top-0 w-[100%] h-full bg-black ease-in-out duration-500' : 'fixed left-[-100%]'}>
                <div className='w-full h-full relative'>
                    <div className=' bgmenu absolute w-full h-full opacity-40 -z-10'>
                    </div>
                    {
                        openMenu &&
                        <div className='container flex flex-col md:flex-col items-center justify-center  h-[100%] gap-10 px-5 md:px-0 z-50'>
                            <div className='flex flex-col-reverse md:flex-row justify-between md:h-[70%] items-center gap-10 md:gap-0 '>
                                <div className='flex gap-5 items-center'>
                                    <p className='text-primary opacity-50 row-start-2 text-justify text-base md:text-xl md:w-[45%]'>
                                        Nos especializamos en la digitalización
                                        de procesos empresariales, ofreciendo
                                        consultoría que impulsa la eficiencia
                                        y la innovación.
                                    </p>
                                    <img
                                        src={"https://awna.s3.sa-east-1.amazonaws.com/Asset1.webp"}
                                        className="static w-[30px] h-[200px] object-cover z-0 hidden md:block"
                                        alt="Decorative Asset" />
                                </div>
                                <div className='flex flex-col md:gap-14 2xl:gap-24 gap-5 justify-end h-full w-full'>
                                    <NavLink onMouseEnter={selectEnter} onMouseLeave={navBarEnter} to={'/'}>
                                        <motion.p className='text-white text-3xl lg:text-[90px]  hover:opacity-100 opacity-50 duration-300 transition-all    text-start md:text-end'>Home</motion.p>
                                    </NavLink>
                                    <NavLink onMouseEnter={selectEnter} onMouseLeave={navBarEnter} to={'/aboutus'}>
                                        <motion.p className='text-white text-3xl lg:text-[90px]  hover:opacity-100 opacity-50 duration-300 transition-all    text-start md:text-end'>Nosotros</motion.p>
                                    </NavLink>
                                    <NavLink onMouseEnter={selectEnter} onMouseLeave={navBarEnter} to={'/services'}>
                                        <motion.p className='text-white text-3xl lg:text-[90px]  hover:opacity-100 opacity-50 duration-300 transition-all    text-start md:text-end'>Servicios</motion.p>
                                    </NavLink>
                                    <NavLink onMouseEnter={selectEnter} onMouseLeave={navBarEnter} to={'/blog'}>
                                        <motion.p className='text-white text-3xl lg:text-[90px]  hover:opacity-100 opacity-50 duration-300 transition-all    text-start md:text-end'>Blog</motion.p>
                                    </NavLink>
                                    {/* <motion.p className='text-white text-3xl lg:text-[90px]  hover:opacity-100 opacity-50    text-start md:text-end'>BLOG</motion.p>
                            <motion.p className='text-white text-3xl lg:text-[90px]  hover:opacity-100 opacity-50    text-start md:text-end'>CLIENTES</motion.p> */}
                                    <NavLink onMouseEnter={selectEnter} onMouseLeave={navBarEnter} to={'/contact'}>
                                        <motion.p className='text-white text-3xl lg:text-[90px]  hover:opacity-100 opacity-50    text-start md:text-end'>Contacto</motion.p>
                                    </NavLink>
                                </div>
                            </div>
                            <div className='w-full md:mt-10'>
                                <div className='flex flex-col md:flex-row justify-between  gap-5  w-full'>
                                    <NavLink onMouseEnter={selectEnter} onMouseLeave={navBarEnter} to={'/contact'}>
                                        <Button className='bg-primary rounded-none !text-secondary text-base py-5 px-10 cursor-none w-full '>
                                            contacto@awna.cl
                                        </Button>
                                    </NavLink>
                                    <div className='md:flex grid grid-cols-3 gap-5'>
                                        {socialNetworks.map(({ Icon, name, url }) => (
                                            <a key={name} href={url} target="_blank" rel="noopener noreferrer" className="flex items-center justify-center gap-3 bg-black text-primary py-2 px-4 border border-white text-base rounded-none w-full md:w-full transition-all duration-300 group relative overflow-hidden hover:bg-white hover:text-black hover:border-black">
                                                <span className="absolute bottom-0 left-0 h-0.5 bg-white transition-all duration-300 group-hover:w-full group-hover:bg-black w-0"></span>
                                                <span className="font-medium tracking-wider">
                                                    {name}
                                                </span>
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div >
    )
}

export default Navbar