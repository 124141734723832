import React, { useContext } from 'react'
import Cta from '../../ui/cta'
import { motion } from 'framer-motion'
import CursorContext from '../../providers/Cursor'
import BgColorContainer from '../../Layout/BgColorContainer'


function AboutUs() {
    // const bottomRef = React.useRef();
    // const onClick = () => {
    //     bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    // }
    const { cursorVariant } = useContext(CursorContext)

    return (
        <BgColorContainer color={'white'}>

            <div className='2xl:h-min pb-32 flex items-center bg-primary'>
                <div className='max-w-[1200px] mx-auto flex flex-col md:flex-row gap-10 md:gap-5 justify-between items-center p-5 md:p-0 mt-20'>
                    <img src='https://awna.s3.sa-east-1.amazonaws.com/1.png' alt='about us' className='md:w-[500px] md:h-[600px] object-cover object-center' />
                    <div className='flex flex-col items-end '>
                        <div className=' lg:w-[70%] 2xl:w-[75%] flex flex-col gap-7 md:gap-10'>
                            <p className='text-secondary text-justify text-lg opacity-50'>
                                Emergemos como una propuesta innovadora en una industria en constante evolución, donde la tecnología y la transformación digital son claves para destacar.
                                Somos más que una consultora; somos tu equipo extendido, preparado para desarrollar estrategias 360° que cubren todo el proceso, desde la planificación hasta la ejecución integral, sin que tengas que depender de diferentes agencias para cada proceso.
                                En un mundo en constante evolución, Awna se dedica a ser el socio confiable que necesitas para adaptarte y prosperar. Creemos en la innovación como motor de desarrollo y en el poder de lo digital para transformar y expandir las oportunidades de tu empresa.
                            </p>
                            <div className='flex overflow-hidden z-20 self-end'>
                                <Cta text={'Contacto'} link={'/contact'} />
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </BgColorContainer>
    )
}

export default AboutUs