import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import Cta from '../../../ui/cta';
import { cn } from '../../../../utils/cn';
import { NavLink } from 'react-router-dom';
import CursorContext from '../../../providers/Cursor';


function CardServiceDetail({ servicio, item }) {

    const { selectEnter, selectExit } = useContext(CursorContext)

    console.log(item)

    return (
        <>
            <div className={
                cn('flex flex-col gap-4 p-5 px-5 w-[300px] max-w-[300px] rounded-3xl border border-secondary', {
                    'bg-black !text-primary': servicio.color == 'white',
                    'bg-primary text-secondary': servicio.color !== 'white',
                })
            }>
                <h3 className='text-2xl text-center'>
                    {item.titulo}
                </h3>
                {
                    item?.precios && (
                        <>
                            {
                                item?.precios.map((precio, idx) => (
                                    <div className='flex flex-col gap-1'>
                                        <p>
                                            {precio.title}
                                        </p>
                                        <p className='text-2xl font-bold'>
                                            {precio.precio}
                                        </p>

                                    </div>
                                ))
                            }
                        </>
                    )
                }
                {
                    item?.precio && !item?.precios && (
                        <p className='text-3xl flex items-center justify-between font-bold'>
                            <span className='text-base font-bold self-end'>Desde</span>
                            {item.precio}
                        </p>

                    )
                }
                <div className='inline-flex w-full border-b-2 border-secondary h-1 opacity-80'></div>

                <p className='text-xs font-bold'>
                    {item.descripcion}
                </p>

                <ul className='flex flex-col gap-5 mb-5'>
                    {item.caracteristicas && (
                        item.caracteristicas.map((caracteristica, idx) => (
                            <li className='text-[13px] md:text-[12px] -mb-0.5' key={idx}>
                                • {caracteristica}
                            </li>
                        )))}
                </ul>
                {/* <div className='pt-4'>
                    <p className='text-[13px] md:text-[12px] font-bold text-black -mb-[1.5px]'>Objetivo: {item.objetivo}</p>
                    <p className='text-[13px] md:text-[12px] text-black -mb-[1.5px]'>{item.tiempo}</p>
                </div> */}

                {item.inversion && (
                    <p className='text-xs font-bold '>
                        {item.inversion.title}: {item.inversion.description}
                    </p>
                )}

                {
                    item.bold && item.bold.map((bold, idx) => (
                        <p className='text-xs font-bold' key={idx}>
                            {bold}
                        </p>
                    ))
                }

                {
                    item?.dirigido && (
                        <p className='text-xs font-bold'>
                            {item.dirigido}
                        </p>
                    )
                }

                <NavLink
                    onMouseEnter={selectEnter}
                    onMouseLeave={selectExit}
                    to="/contact" className={
                        cn('w-full flex items-center justify-center mt-auto text-center px-14 py-2 rounded-lg duration-300 transition-all minText', {
                            'bg-primary hover:bg-secondary text-secondary hover:text-primary': servicio.color == 'white',
                            'bg-secondary hover:bg-zinc-500 text-primary ': servicio.color !== 'white'
                        })
                    }>
                    <button className="w-full cursor-none">Cotizar</button>
                </NavLink>
            </div >


        </>

    )
}

export default CardServiceDetail