import React, { useContext, useEffect, useRef } from 'react'
import BgColorContainer from '../../Layout/BgColorContainer'
import { useScroll } from 'framer-motion'
import { Button } from 'antd'
import CursorContext from '../../providers/Cursor';

function Hero() {

    const { blackSectionEnter, blackSectionExit } = useContext(CursorContext);

    const ref = useRef(null)
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["end end", "start start"],
    })

    useEffect(() => {
        console.log(scrollYProgress)
    }, [scrollYProgress])



    return (
        <BgColorContainer >

            <div ref={ref} className='w-full bg-primary relative'>
                <video autoPlay muted loop className='w-full h-full object-cover absolute top-0 left-0 z-index-0'>
                    <source src={'https://awna.s3.sa-east-1.amazonaws.com/Awna_+metaball_+anim_+3_+blur.mp4'} type='video/mp4' />
                </video>
                <div className='max-w-[1200px] mx-auto h-screen flex items-center justify-center relative text-white gap-2 px-5 md:px-0'>
                    <div className='w-full flex flex-col-reverse md:flex-row justify-between items-center'>
                        <div className='flex flex-col md:items-start items-center justify-center gap-5 mt-10 md:mt-0'>
                            <p className='text-base text-center md:text-start'>
                                CONSULTORA DE TRANSFORMACIÓN DIGITAL Y MARKETING
                            </p>
                            <h1 className=' text-3xl md:text-5xl !leading-normal text-center md:text-start'>
                                {'Desarrollamos '}
                                <br className='md:hidden block' />
                                <span className='bg-[#D9D9D9] text-[#333333] font-bold px-2 pb-2'>
                                    estrategias 360°
                                </span>
                                <br className='md:hidden block' />
                                <br className='hidden md:block' />
                                para planificar, ejecutar y hacer crecer<br className='hidden md:block' /> tu empresa.
                            </h1>
                            <Button
                                href='https://meet.brevo.com/awna/reunion-de-30-minutos'
                                target='_blank'
                                onMouseEnter={blackSectionEnter}
                                onMouseLeave={blackSectionExit}
                                type='primary' className='bg-primary text-secondary w-min p-5 rounded-none text-base px-10 hover:!bg-secondary hover:!text-primary'>
                                Agendar Reunión
                            </Button>
                        </div>
                        <img src={'https://awna.s3.sa-east-1.amazonaws.com/LogoWhite3.webp'} className='w-[200px]  z-index-10 ' />
                    </div>
                    {/* <Model /> */}
                </div>

                {/* <img src={'https://awna.s3.sa-east-1.amazonaws.com/Moleculas/MoleculaHome.png'} className='absolute 2xl:-bottom-[850px] z-index-0 2xl:w-auto md:w-2/3 md:-bottom-[500px] hidden md:block' /> */}
            </div>
        </BgColorContainer>
    )
}

export default Hero