import React from 'react';
import { PiArrowDownRightThin } from "react-icons/pi";
import NosotrosCard from '../../ui/NosotrosCard';
import NosotrosData from '../../../Data/Nosotros.json';
import BgColorContainer from '../../Layout/BgColorContainer';


function Nosotros() {

    return (
        <BgColorContainer color={'white'} >

            <div className='bg-primary w-full h-min p-5 md:p-0'>
                <div className='container'>
                    <div className="flex flex-row items-center rounded-lg md:py-10 py-5 gap-4 ">
                        <h1 className='text-secondary text-3xl font-bold opacity-80'>Nosotros</h1>
                        <PiArrowDownRightThin className='opacity-70 w-8 h-8' />
                    </div>
                </div>
                <div className='inline-flex w-full border-dashed border-b-2 border-black h-1 opacity-40 md:mt-6'></div>
                <div className='container md:py-20 flex flex-col md:gap-20 gap-5'>
                    <div className='w-full grid md:grid-cols-3 gap-5 justify-center justify-items-center items-center py-5'>
                        <div className=''>
                            <p className='md:text-xl text-base text-justify  text-black opacity-40 '>
                                Somos un equipo de trabajo resolutivo y dinámico,
                                enfocado en encontrar soluciones innovadoras de manera ágil.
                                Nos adaptamos rápidamente a los cambios para superar
                                cualquier desafío.
                            </p>
                        </div>
                        <NosotrosCard person={NosotrosData[1]} />
                        <NosotrosCard person={NosotrosData[0]} />
                    </div>
                    <div className='w-full grid md:grid-cols-2 gap-5 justify-center justify-items-center'>
                        <NosotrosCard person={NosotrosData[2]} />
                        <NosotrosCard person={NosotrosData[3]} />
                    </div>
                    <div className='w-full grid md:grid-cols-3 gap-5 justify-center justify-items-center'>
                        <NosotrosCard person={NosotrosData[4]} />
                        <NosotrosCard person={NosotrosData[5]} />
                        <NosotrosCard person={NosotrosData[6]} />
                    </div>
                    <div className='w-full grid md:grid-cols-3 gap-5 justify-center justify-items-center'>
                        <NosotrosCard person={NosotrosData[7]} />
                        {/* <NosotrosCard person={NosotrosData[8]} /> */}
                        <NosotrosCard person={NosotrosData[9]} />
                        <NosotrosCard person={NosotrosData[10]} />
                        {/* <NosotrosCard person={NosotrosData[8]} /> */}
                    </div>
                    <div className='w-full grid md:grid-cols-2 gap-5 justify-center justify-items-center'>
                        <NosotrosCard person={NosotrosData[11]} />
                        <NosotrosCard person={NosotrosData[12]} />
                    </div>
                </div>
            </div>
        </BgColorContainer>
    )
}

export default Nosotros