import { BrowserRouter, Route, Routes, useLocation, useRoutes } from "react-router-dom";
import Home from "./components/Pages/Home";
import AboutUs from "./components/Pages/AboutUs";
import Services from "./components/Pages/Services"
import Contact from "./components/Pages/Contact";
import Blog from "./components/Pages/Blog";
import EntradaDeBlog from "./components/Pages/EntradaDeBlog";
import DetalleServicio from './components/Sections/Services/ServicesDetail/DetalleServicio';
import EntradaData from './Data/Entradas.json'
import ServiciosData from './Data/Servicios.json';
import ServicesDetail from "./components/Pages/ServicesDetail";
import { AnimatePresence } from "framer-motion";
import React from "react";

function App() {


  const element = useRoutes([

    {
      path: "/",
      element: <Home />
    },
    {
      path: "/aboutus",
      element: <AboutUs />
    },
    {
      path: "/services",
      element: <Services />
    },
    {
      path: "/contact",
      element: <Contact />
    },
    {
      path: "/services/:id",
      element: <ServicesDetail ServiciosData={ServiciosData} />
    },
    {
      path: "/blog",
      element: <Blog />
    },
    {
      path: "/blog/:id",
      element: <EntradaDeBlog EntradaData={EntradaData} />
    }

  ])

  const location = useLocation();

  if (!element) return null


  return (
    <AnimatePresence mode="wait" initial={true}>
      {
        React.cloneElement(element, { key: location.pathname })
      }
    </AnimatePresence>
  );
}

export default App;
